:root {
    --primary: #00325F;
    --primary-900: #002247;
    --primary-800: #00325F;
    --primary-700: #063B6B;
    --primary-600: #134476;
    --primary-500: #1B4B7E;
    --primary-400: #44648E;
    --primary-300: #657EA0;
    --secondary: #E60032;
    --dark: #F5F5F5;
    --surface: #FFF;
    --light: #FFF;
    --background: #F0F9FC;
    --focus: #80bdff;
    --focus-shadow: rgba(0,123,255,.25);
    --valid: #3c3;
    --valid-shadow: rgba(51,153,0,.25);
    --invalid:#F00;
    --invalid-shadow: rgba(204,0,0,.25);
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

header {
    background-image: url("./assets/img/Background.png");
    background-size: cover;
}

header img{
    margin-left: 15%;
    margin-top: 35vh;
}

header p{
    color: var(--light);
    margin-left: 15%;
    margin-top: 1vh;
    font-size: 1rem;
}

header .row {
    padding: 2% 0;
}

p{
    color: var(--primary);
    margin-top: 3vh;
    font-size: 1.5rem;
}

.required {
    color: #CC001C;
}

.form-group {
    position: relative;
    margin-bottom: 2rem;
}

.form-label {
    position: absolute;
    left: 1.5rem;
    top: 20px;
    color: #999;
    background-color: transparent;
    z-index: 10;
    transition: transform 150ms ease-out, font-size 150ms ease-out;
}

.focused .form-label {
    transform: translateY(-150%);
    padding: 0 5px;
    font-size: .75em;
    color: var(--primary);
}

.form-input {
    position: relative;
    padding: 12px 0px 5px 1.5rem;
    width: 100%;
    outline: 0;
    border: 2px solid var(--primary);
    transition: box-shadow 150ms ease-out;
    height: 60px;
}

.form-input, .form-label {
    background-color: var(--dark);
}

.form-control:focus {
    border-color: var(--focus);
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--focus-shadow);
}

.form-input:focus {
    background-color: var(--dark);
    color: var(--primary);
}

.valid {
    border-color: var(--valid);
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--valid-shadow);
}

.invalid {
    border-color: var(--invalid);
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--invalid-shadow);
}

.wrapper{
    background-color: var(--background);
    padding: 5%;
}

.title{
    color: var(--light);
    margin-top: 1vh;
    margin-left: 15%;
    font-weight:bold;
}

form, .form{
    margin-top:8vh;
}

#submit{
    margin-top: 10vh;
    margin-right: 15px;
    width: 150px;
}

.k-dialog-title{
    padding: 5px 0;
}

.k-dialog {
    width: 50vw;
}
.btn-primary {
    color: var(--light);
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-primary:hover {
    color: var(--light);
    background-color: var(--primary-600);
    border-color: var(--primary-600);
}

    .btn-primary:focus, .btn-primary.focus {
        box-shadow: 0 0 0 0.2rem rgba(101, 126, 160, 0.5);
    }

    .btn-primary.disabled, .btn-primary:disabled {
        color: var(--light);
        background-color: var(--primary-300);
        border-color: var(--primary-300);
    }

    .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
        color: var(--light);
        background-color: var(--primary-500);
        border-color: var(--primary-500);
    }

        .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
        .show > .btn-primary.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.2rem rgba(101, 126, 160, 0.5);
        }

@media (max-width: 575.98px) {
    h1 {
        font-size: .5rem;
    }

    header p {

    }

    p{

    }
}

@media (max-width: 576px){
    h1 {
        font-size: 1.5rem;
    }

    header {
        display: none;
    }

    p {
        font-size: 1rem;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    h1 {
        font-size: 1.5rem;
    }

    header {
        display:none;
    }

    p {
        font-size: 1rem;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    h1 {
        font-size: 1.5rem;
    }

    header {
        display: none;
    }

    p {
        font-size: 1rem;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    h1 {
        font-size: 2rem;
    }

    header p {
        font-size: 1rem;
    }

    p {
        font-size: 1.5rem;
    }
}

@media (min-width: 1200px) {
    h1{
        font-size: 2.5rem;
    }

    header p {
        font-size: 1rem;
    }

    p {
        font-size:1.5rem;
    }
}